import {Component} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { loaderColor, TITLE } from 'brand/telepagos/proyect';
import { Spinkit } from 'ng-http-loader';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent{
    public spinkit = Spinkit;
    title =  TITLE;
    loaderColor = loaderColor;

    filteredUrlPatterns : string[] = [
      'qr/firma',
      'qr/pix/estado',
      'qr/consultar',
    ]

    public constructor(private titleService: Title) {
        titleService.setTitle(this.title)
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }
}
