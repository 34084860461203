//Angular Modules
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
//Routing
import {AppRoutingModule} from '@/app-routing.module';
//App built-in components
import {AppComponent} from './app.component';

//Store
import { StoreModule } from '@ngrx/store';
import { uiReducer } from './store/ui/reducer';

//Inicializador
import { AutentificacionService } from '@services/autentificacion.service';

//Interceptors
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';

//Mensajes
import {ToastrModule} from 'ngx-toastr';
//Loader
import { NgHttpLoaderModule } from 'ng-http-loader';
import { MatDialogModule } from '@angular/material/dialog';
import { usuarioLogueadoReducer } from './store/usuario-logueado/reducer';


registerLocaleData(localeEn, 'en-EN');

export function initApp(autentificacionService: AutentificacionService) {
  return () => {
    if (autentificacionService.estaLogueado()) {
      autentificacionService.me().subscribe();
    }
  }
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        StoreModule.forRoot({ui: uiReducer, usuarioLogueado: usuarioLogueadoReducer}),
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-bottom-center',
            preventDuplicates: true,
            closeButton: true,
            enableHtml: true,
        }),
        NgHttpLoaderModule.forRoot(),
        MatDialogModule
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        // { provide: LOCALE_ID, useValue: 'fr-FR'},
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory:initApp,multi:true,deps:[AutentificacionService]}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
