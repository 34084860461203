import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { IComercio, IUsuario } from "../models/usuario.model";
import { environment } from "./../../environments/environment";
import { ApiCommonResponse } from "@/interfaces/responses/common/ApiResponse";
import { tap } from "rxjs/operators";
import { LoginDTO } from "@/interfaces/dtos/login.dto";
import { Menu } from "@/models/menu.model";
import { Store } from "@ngrx/store";
import { AppState } from "@/store/state";
import { deleteUsuarioLogueado, saveUsuarioLogueado } from "@/store/usuario-logueado/actions";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: "root",
})
export class AutentificacionService {
  url = environment.url;

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<AppState>,
    private dialogRef: MatDialog
  ) { }

  login(email: string, password: string, cuit: string) {
    let data: LoginDTO = {
      email,
      password,
      cuit
    }

    return this.http.post<ApiCommonResponse & { token: string }>(this.url + "cobro/login", data);
  }

  me() {
    return this.http.get<ApiCommonResponse & { usuario: IUsuario, menus: Menu[] , comercio : IComercio}>(this.url + "cobro/me").pipe(tap((res) => {
      if (res.status == "ok") {
        const usuario : IUsuario = { ...res.usuario, menus: res.menus, comercio: res.comercio };
        this.store.dispatch(saveUsuarioLogueado({ usuario }));
      } else {
        this.logOut();
      }
    }));
  }

  logOut() {
    this.http.post<ApiCommonResponse>(this.url + "back/logout", {}).subscribe();
    this.store.dispatch(deleteUsuarioLogueado());
    localStorage.clear();
    sessionStorage.clear()
    this.router.navigate(["/login"]);
  }

  expulsar() {
    this.store.dispatch(deleteUsuarioLogueado());
    localStorage.clear();
    sessionStorage.clear()
    this.dialogRef.closeAll();
    this.router.navigate(["/login"]);
  }

  estaLogueado() {
    return localStorage.getItem("access_token") !== null;
  }

  changePassword(password_anterior: string, password: string) {
    return this.http.put<ApiCommonResponse>(this.url + 'cobro/password', { password_anterior, password });
  }

}
